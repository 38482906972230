import { Button, Col, Input, Row, Space, Typography } from 'antd'
/* eslint-disable-next-line */
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import React, { useContext } from 'react'
import { StepperFormContext } from 'containers/app/screens/SignUp/providers/StepperFormProvider'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Header } from 'containers/shared/components/typography/Header'
import { Text } from 'containers/shared/components/typography/Text'
import styles from './styles.module.scss'

type RegisterAccountFormProps = {
  nextStep: () => void
}

export const ProfileForm = ({ nextStep }: RegisterAccountFormProps) => {
  const { formData, setFormData } = useContext(StepperFormContext)
  return (
    <Row justify='center' style={{ width: '100%' }}>
      <Col flex='0 1 100%'>
        <Header level={3} size={'m'}>
          Create your profile with some basic info
        </Header>
      </Col>
      <Col flex='0 1 100%'>
        <Row>
          <Col flex='0 1 100%'>
            <Formik
              initialValues={{
                firstName: formData.sellerAttributes['firstName'],
                lastName: formData.sellerAttributes['lastName'],
                password: formData.password,
                passwordConfirmation: formData.passwordConfirmation,
              }}
              validate={values => {
                const errors = {}

                if (values.password !== values.passwordConfirmation) {
                  errors['passwordConfirmation'] = 'Passwords must match'
                }
                Object.keys(values).forEach(key => {
                  if (values[key] === '') {
                    errors[key] = 'Required field'
                  }
                })

                return errors
              }}
              onSubmit={values => {
                setFormData(prev => ({
                  ...prev,
                  password: values.password,
                  passwordConfirmation: values.passwordConfirmation,
                  sellerAttributes: {
                    ...prev.sellerAttributes,
                    firstName: values.firstName,
                    lastName: values.lastName,
                  },
                }))
                nextStep()
              }}
            >
              {(props: FormikProps<any>) => (
                <Form>
                  <Space direction='vertical' size={32} style={{ width: '100%' }}>
                    <Field name='firstName'>
                      {({ field, meta }: FieldProps) => (
                        <>
                          <Input {...field} status={meta.touched && meta?.error && 'error'} placeholder='First Name' />
                          <ErrorMessage name='firstName'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                    <Field name='lastName'>
                      {({ field, meta }: FieldProps) => (
                        <>
                          <Input
                            {...field}
                            status={meta.touched && meta?.error && 'error'}
                            placeholder='Last Name'
                            min={8}
                            max={8}
                          />
                          <ErrorMessage name='lastName'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                    <Field name='password'>
                      {({ field, meta }: FieldProps) => (
                        <>
                          <Input.Password
                            {...field}
                            status={meta.touched && meta?.error && 'error'}
                            placeholder='Create password'
                          />
                          <ErrorMessage name='password'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                    <Field name='passwordConfirmation'>
                      {({ field, meta }: FieldProps) => (
                        <>
                          <Input.Password
                            {...field}
                            status={meta.touched && meta?.error && 'error'}
                            placeholder='Confirm password'
                            min={8}
                            max={8}
                          />
                          <ErrorMessage name='passwordConfirmation'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                    <Button
                      block
                      size='large'
                      type='primary'
                      onClick={props.submitForm}
                      disabled={props.isSubmitting || !props.isValid}
                    >
                      <Row justify='center' align='middle'>
                        <Text className={styles.nextButtonContent} size='l'>
                          Next
                        </Text>
                        <ArrowRightOutlined style={{ fontSize: '26px' }} />
                      </Row>
                    </Button>
                  </Space>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
