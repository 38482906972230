import { signUpStepperPreview } from 'containers/app/screens/SignUp/data/theme/steppers/signUpStepperPreview'
import { Card, Row, Steps } from 'antd'
import { SignUpFooter } from 'containers/app/screens/SignUp/components/page/SignUp/SignUpFooter'
import { Header } from 'containers/shared/components/typography/Header'
import { ThemeProvider } from '../../../../../components/providers/ThemeProvider'
import styles from './styles.module.scss'
import { PreviewTitle } from './PreviewTitle/PreivewTitle'

type IStepperPreviewProps = {
  current: number
  items: Array<{ key: string; title: string; description: string }>
}

export const StepperPreview = ({ current, items }: IStepperPreviewProps) => (
  <ThemeProvider theme={signUpStepperPreview}>
    <Card className={styles.card}>
      <Row justify='center' className={styles.cardHeaderMb}>
        <PreviewTitle />
      </Row>
      <Row justify='center' className={styles.cardTitleMb}>
        <Header level={1} size='xl'>
          {'Welcome'}
          <br />
          {'to Pitaya!'}
        </Header>
      </Row>
      <Row justify='center' className={styles.cardContentMb}>
        <Steps direction='vertical' current={current} items={items} size='small' />
      </Row>
      <SignUpFooter />
    </Card>
  </ThemeProvider>
)
