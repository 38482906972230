import {globalColors} from "../../../../../data/colors"

export const SignUpFooter = {
  token: {
    inherit: true,
    fontSize: 14,
    colorBgContainer: globalColors.white,
    colorBorderSecondary: globalColors.white,
    paddingLG: 24
  }
}
