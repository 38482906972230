import React, { createContext, ReactNode, useState } from 'react'
import { SignUpParams } from 'containers/shared/api/session.api'

type StepperFormProviderProps = {
  children: ReactNode
}

type StepperFormContextValue = {
  formData: SignUpParams
  setFormData: React.Dispatch<React.SetStateAction<SignUpParams>>
}

export const StepperFormContext = createContext<StepperFormContextValue | undefined>(undefined)

const StepperFormProvider = ({ children }: StepperFormProviderProps) => {
  const [formData, setFormData] = useState<SignUpParams>({
    email: '',
    password: '',
    passwordConfirmation: '',
    sellerAttributes: {
      phone: '',
      monthlySales: [],
      countryCode: [],
      firstName: '',
      lastName: '',
      username: '',
      employeesCount: [],
      usageReason: [],
      companyRole: [],
    },
  })
  return <StepperFormContext.Provider value={{ formData, setFormData }}>{children}</StepperFormContext.Provider>
}

export default StepperFormProvider
