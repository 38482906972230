const Logo = ({ width, height }: { width?: string | number; height?: string | number }) => (
  <svg
    width={width || '147'}
    height={height || '34'}
    viewBox={`0 0 147 34`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_1699_7596)'>
      <path
        d='M0 28.0678H1.91912C1.91912 28.0678 4.61057 0.79311 30.1677 1.79723C53.1269 2.68459 52.4014 28.3014 52.4014 28.3014H54.2503C54.2503 28.3014 55.1864 0.0458573 29.5123 -0.000845971C2.01274 -0.0709009 0 28.0678 0 28.0678Z'
        fill='url(#paint0_linear_1699_7596)'
      />
      <path
        d='M3.91016 28.1381H6.97607C6.97607 28.1381 9.41008 6.88805 27.9928 7.00481C45.0777 7.12157 46.2947 28.1147 46.2947 28.1147H50.601C50.601 28.1147 49.5946 4.0625 28.8119 4.0625C5.61864 4.0625 3.91016 28.1381 3.91016 28.1381Z'
        fill='url(#paint1_linear_1699_7596)'
      />
      <path
        d='M11.9963 27.4211C13.4569 27.4211 14.641 26.9782 14.641 26.4318C14.641 25.8853 13.4569 25.4424 11.9963 25.4424C10.5356 25.4424 9.35156 25.8853 9.35156 26.4318C9.35156 26.9782 10.5356 27.4211 11.9963 27.4211Z'
        fill='black'
      />
      <path
        d='M11.8446 19.3567C11.6041 19.8364 12.4757 20.7658 13.798 21.3954C15.1204 22.025 16.3827 22.1449 16.6231 21.6652C16.8635 21.1856 15.992 20.2561 14.6696 19.6265C13.3472 18.9669 12.115 18.847 11.8446 19.3567Z'
        fill='black'
      />
      <path
        d='M20.7692 17.2682C21.1564 16.8817 20.6332 15.7332 19.6005 14.7028C18.5678 13.6723 17.4166 13.1503 17.0293 13.5367C16.6421 13.9231 17.1653 15.0716 18.198 16.1021C19.2308 17.1325 20.3819 17.6546 20.7692 17.2682Z'
        fill='black'
      />
      <path
        d='M24.5851 9.85286C24.0441 9.97278 23.8638 11.202 24.1643 12.6411C24.4648 14.0802 25.1561 15.1295 25.667 15.0096C26.208 14.8897 26.3883 13.6605 26.0878 12.2214C25.7872 10.8123 25.126 9.73293 24.5851 9.85286Z'
        fill='black'
      />
      <path
        d='M31.3155 9.82262C30.7746 9.67272 30.0232 10.6621 29.6025 12.0712C29.1817 13.4803 29.2719 14.7395 29.7828 14.8894C30.3238 15.0393 31.0751 14.05 31.4959 12.6408C31.9467 11.2317 31.8565 9.97253 31.3155 9.82262Z'
        fill='black'
      />
      <path
        d='M42.1517 27.8992C43.6107 27.8317 44.7729 27.3345 44.7476 26.7886C44.7222 26.2428 43.5188 25.8551 42.0598 25.9226C40.6008 25.9902 39.4385 26.4874 39.4639 27.0332C39.4893 27.579 40.6927 27.9668 42.1517 27.8992Z'
        fill='black'
      />
      <path
        d='M40.8453 21.7401C42.1438 21.0726 42.9931 20.1378 42.7423 19.652C42.4914 19.1662 41.2355 19.3135 39.937 19.9809C38.6386 20.6484 37.7893 21.5833 38.0401 22.069C38.2909 22.5548 39.5469 22.4076 40.8453 21.7401Z'
        fill='black'
      />
      <path
        d='M36.9727 16.0494C37.9108 14.9325 38.3309 13.7425 37.9111 13.3914C37.4913 13.0404 36.3905 13.6614 35.4524 14.7783C34.5144 15.8953 34.0942 17.0853 34.514 17.4363C34.9339 17.7873 36.0346 17.1664 36.9727 16.0494Z'
        fill='black'
      />
      <path
        d='M23.0043 23.4536C23.1868 23.1381 22.6007 22.4595 21.6952 21.9378C20.7897 21.4162 19.9076 21.249 19.7251 21.5645C19.5425 21.88 20.1286 22.5586 21.0341 23.0803C21.9397 23.6019 22.8217 23.7691 23.0043 23.4536Z'
        fill='black'
      />
      <path
        d='M23.9559 18.1577C24.2865 17.9778 24.9477 18.6074 25.4286 19.5368C25.9094 20.4662 26.0297 21.3656 25.6991 21.5156C25.3685 21.6954 24.7073 21.0658 24.2264 20.1364C23.7756 19.207 23.6254 18.3076 23.9559 18.1577Z'
        fill='black'
      />
      <path
        d='M18.3352 26.3721C18.4254 26.0123 19.2969 25.8924 20.3188 26.1023C21.3406 26.3122 22.122 26.7919 22.0318 27.1516C21.9417 27.5114 21.0701 27.6313 20.0483 27.4215C19.0264 27.1816 18.245 26.7319 18.3352 26.3721Z'
        fill='black'
      />
      <path
        d='M33.953 22.8376C34.8586 22.3159 35.4446 21.6373 35.2621 21.3218C35.0795 21.0063 34.1975 21.1735 33.2919 21.6951C32.3864 22.2168 31.8003 22.8954 31.9829 23.2109C32.1654 23.5264 33.0475 23.3592 33.953 22.8376Z'
        fill='black'
      />
      <path
        d='M30.5973 17.8574C30.2667 17.7075 29.6657 18.3971 29.275 19.3565C28.8843 20.3159 28.8241 21.2153 29.1848 21.3652C29.5154 21.5151 30.1165 20.8255 30.5072 19.8661C30.8678 18.8768 30.9279 17.9773 30.5973 17.8574Z'
        fill='black'
      />
      <path
        d='M36.9068 25.7422C36.8167 25.3825 35.9451 25.2625 34.9233 25.4724C33.9014 25.6823 33.12 26.162 33.2102 26.5217C33.3004 26.8815 34.1719 27.0014 35.1938 26.7916C36.2156 26.5517 36.997 26.102 36.9068 25.7422Z'
        fill='black'
      />
      <path
        d='M69.883 13.8711C71.8489 13.8711 73.4872 14.5716 74.751 15.9961C76.0148 17.3972 76.6467 19.1486 76.6467 21.2269C76.6467 23.2818 76.0148 25.0332 74.751 26.4343C73.4872 27.8587 71.8489 28.5593 69.883 28.5593C67.7298 28.5593 66.0681 27.812 64.8979 26.2942V33.8601H61.832V14.2447H64.8979V16.1362C66.0681 14.6183 67.7298 13.8711 69.883 13.8711ZM69.0638 16.5565C68.1745 16.5565 67.3554 16.79 66.6064 17.2571C65.8575 17.7241 65.2958 18.3313 64.8979 19.0552V23.3752C65.2958 24.0991 65.8809 24.6829 66.6298 25.1499C67.3788 25.617 68.1979 25.8505 69.0638 25.8505C70.3511 25.8505 71.4276 25.4068 72.2702 24.5194C73.1127 23.6087 73.5574 22.5112 73.5574 21.2269C73.5574 19.9192 73.1361 18.8216 72.2702 17.9343C71.4042 17.0236 70.3511 16.5565 69.0638 16.5565Z'
        fill='black'
      />
      <path d='M83.3634 28.1861H80.2969V14.2451H83.3634V28.1861Z' fill='black' />
      <path
        d='M89.4488 24.3794V16.9303H86.4531V14.2448H89.4488V10.1816H92.5147V14.2448H96.9147V16.9536H92.5147V23.5154C92.5147 24.286 92.702 24.8698 93.053 25.2201C93.4041 25.5704 93.9658 25.7572 94.7147 25.7572C95.4636 25.7572 96.1658 25.5937 96.8211 25.2435V27.8822C96.1658 28.3259 95.1126 28.5594 93.7083 28.5594C92.3743 28.5594 91.3445 28.2091 90.5722 27.4852C89.8467 26.7613 89.4488 25.7105 89.4488 24.3794Z'
        fill='black'
      />
      <path
        d='M113.53 25.8038C113.835 25.8038 114.115 25.7337 114.349 25.5936V27.9288C113.928 28.2324 113.343 28.3725 112.547 28.3725C110.956 28.3725 109.973 27.7186 109.622 26.3876C108.358 27.8354 106.626 28.5359 104.473 28.5359C102.975 28.5359 101.711 28.1156 100.705 27.2749C99.7219 26.4576 99.2305 25.3134 99.2305 23.8422C99.2305 22.3711 99.7688 21.2269 100.845 20.4329C101.899 19.6156 103.233 19.2186 104.824 19.2186C106.509 19.2186 108.007 19.5689 109.294 20.2928V19.5222C109.294 17.4906 107.937 16.4865 105.198 16.4865C103.607 16.4865 102.133 16.9068 100.799 17.7475V15.062C102.179 14.2681 103.888 13.8711 105.901 13.8711C107.867 13.8711 109.435 14.3381 110.605 15.2722C111.775 16.2063 112.36 17.5139 112.36 19.1953V24.4027C112.36 24.8464 112.477 25.1733 112.688 25.4302C112.898 25.6637 113.179 25.8038 113.53 25.8038ZM109.318 24.2392V22.5346C108.147 21.8807 106.907 21.5304 105.596 21.5304C104.567 21.5304 103.771 21.7172 103.186 22.0909C102.601 22.4645 102.32 23.0016 102.32 23.6788C102.32 24.4027 102.601 24.9398 103.139 25.3368C103.677 25.7104 104.426 25.8972 105.339 25.8972C106.088 25.8972 106.837 25.7337 107.562 25.4302C108.264 25.1266 108.873 24.7296 109.318 24.2392Z'
        fill='black'
      />
      <path
        d='M118.515 34.0006C117.368 34.0006 116.502 33.8605 115.988 33.5569V30.8481C116.596 31.1517 117.275 31.2918 118 31.2918C118.773 31.2918 119.428 31.0116 119.99 30.4512C120.528 29.8907 121.066 28.9567 121.628 27.6723L115.496 14.2451H118.913L123.126 24.0762L127.198 14.2451H130.521L123.828 29.6806C122.587 32.5528 120.809 34.0006 118.515 34.0006Z'
        fill='black'
      />
      <path
        d='M146.179 25.8038C146.483 25.8038 146.764 25.7337 146.998 25.5936V27.9288C146.577 28.2324 145.991 28.3725 145.196 28.3725C143.604 28.3725 142.621 27.7186 142.27 26.3876C141.006 27.8354 139.275 28.5359 137.121 28.5359C135.624 28.5359 134.36 28.1156 133.353 27.2749C132.37 26.4576 131.879 25.3134 131.879 23.8422C131.879 22.3711 132.417 21.2269 133.494 20.4329C134.547 19.6156 135.881 19.2186 137.472 19.2186C139.158 19.2186 140.655 19.5689 141.943 20.2928V19.5222C141.943 17.4906 140.585 16.4865 137.847 16.4865C136.255 16.4865 134.781 16.9068 133.447 17.7475V15.062C134.828 14.2681 136.536 13.8711 138.549 13.8711C140.515 13.8711 142.083 14.3381 143.253 15.2722C144.423 16.2063 145.008 17.5139 145.008 19.1953V24.4027C145.008 24.8464 145.126 25.1733 145.336 25.4302C145.547 25.6637 145.828 25.8038 146.179 25.8038ZM141.966 24.2392V22.5346C140.796 21.8807 139.555 21.5304 138.245 21.5304C137.215 21.5304 136.419 21.7172 135.834 22.0909C135.249 22.4645 134.968 23.0016 134.968 23.6788C134.968 24.4027 135.249 24.9398 135.787 25.3368C136.326 25.7104 137.075 25.8972 137.987 25.8972C138.736 25.8972 139.485 25.7337 140.211 25.4302C140.913 25.1266 141.498 24.7296 141.966 24.2392Z'
        fill='black'
      />
      <path
        d='M81.8218 11.7454C81.26 11.7454 80.8152 11.5586 80.4407 11.185C80.0662 10.8114 79.8789 10.3677 79.8789 9.80724C79.8789 9.2468 80.0662 8.80312 80.4407 8.40614C80.8152 8.03252 81.26 7.8457 81.8218 7.8457C82.3602 7.8457 82.8049 8.03252 83.1795 8.40614C83.554 8.77977 83.7413 9.2468 83.7413 9.80724C83.7413 10.3677 83.554 10.8114 83.1795 11.185C82.8049 11.5586 82.3602 11.7454 81.8218 11.7454Z'
        fill='url(#paint2_linear_1699_7596)'
      />
      <path
        d='M84.9558 8.75694C84.9558 7.01672 83.5333 5.60449 81.7917 5.60449C80.0501 5.60449 78.6367 7.01672 78.6367 8.75694C78.6367 10.4972 80.0501 11.9094 81.7917 11.9094C83.5333 11.9185 84.9558 10.5063 84.9558 8.75694Z'
        fill='url(#paint3_linear_1699_7596)'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_1699_7596'
        x1='49.8196'
        y1='21.685'
        x2='0.365767'
        y2='23.7291'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#624BF5' />
        <stop offset='0.46875' stopColor='#FE3A81' />
        <stop offset='1' stopColor='#FFE705' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1699_7596'
        x1='46.7843'
        y1='22.5098'
        x2='4.22664'
        y2='24.2894'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#624BF5' />
        <stop offset='0.46875' stopColor='#FE3A81' />
        <stop offset='1' stopColor='#FFE705' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_1699_7596'
        x1='81.8101'
        y1='7.8457'
        x2='81.8101'
        y2='11.7454'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FF9EBB' />
        <stop offset='1' stopColor='#FFDB82' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_1699_7596'
        x1='81.4567'
        y1='7.81195'
        x2='82.6519'
        y2='11.1924'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#5D4AF7' />
        <stop offset='1' stopColor='#FE3A81' />
      </linearGradient>
      <clipPath id='clip0_1699_7596'>
        <rect width={'147'} height={'34'} fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Logo
