interface HeaderResponsiveSizes {
    xs: { desktop: string; mobile: string };
    s: { desktop: string; mobile: string };
    m: { desktop: string; mobile: string };
    l: { desktop: string; mobile: string };
    xl: { desktop: string; mobile: string };
}

export const headerResponsiveSizes: HeaderResponsiveSizes = {
  xs: { desktop: "16", mobile: "16" },
  s: { desktop: "20", mobile: "16" },
  m: { desktop: "40", mobile: "24" },
  l: { desktop: "48", mobile: "30" },
  xl: { desktop: "96", mobile: "32" },
}
