export const monthlySalesOptions = [
  {
    value: 'under_50k',
    name: 'Under 50k',
  },
  {
    value: 'from_50k_to_200k',
    name: 'Between 50k and 200k',
  },
  {
    value: 'from_200k_to_500k',
    name: 'Between 200k and 500k',
  },
  {
    value: 'from_500k_to_1m',
    name: 'Between 500k and 1m',
  },
  {
    value: 'over_1m',
    name: 'Over 1m',
  },
]

export const employeeCountOptions = [
  {
    value: 'less_then_10',
    name: 'Less 10',
  },
  {
    value: 'from_10_to_20',
    name: 'Between 10 and 20',
  },
  {
    value: 'from_20_to_50',
    name: 'Between 20 and 50',
  },
  {
    value: 'from_50_to_100',
    name: 'Between 50 and 100',
  },
  {
    value: 'over_100',
    name: 'Over 100',
  },
]

export const reasonOptions = [
  {
    value: 'sales',
    name: 'Sales',
  },
  {
    value: 'just_for_fun',
    name: 'For fun',
  },
]

export const roleOptions = [
  {
    value: 'ceo',
    name: 'Seo',
  },
  {
    value: 'manager',
    name: 'Manager',
  },
]
