import { signUpStepperAction } from 'containers/app/screens/SignUp/data/theme/steppers/signUpStepperAction'
import { Row, Typography } from 'antd'
import { Footer } from 'containers/app/screens/SignIn/component/page/SignInFooter'
import { useScreenSize } from 'containers/app/hooks/useScreenSize'
import { SignUpFooter } from 'containers/app/screens/SignUp/data/theme/signUp/signUpFooter'
import { Link } from 'react-router-dom'
import { APP_USERS_SIGN_IN_PATH } from 'constants/routes.constants'
import styles from './styles.module.scss'
import { SignUpFormStepper } from './FormSwitcher'
import { ThemeProvider } from '../../../../../components/providers/ThemeProvider'

type IStepperActionProps = {
  current: number
  nextStep: () => void
}

export const StepperActions = ({ current, nextStep }: IStepperActionProps) => {
  const { breakpoint } = useScreenSize()
  return (
    <ThemeProvider theme={signUpStepperAction}>
      <Row className={styles.layout}>
        <SignUpFormStepper current={current} nextStep={nextStep} />
        {breakpoint !== 'desktop' ? (
          <Link to={APP_USERS_SIGN_IN_PATH} className={styles.link}>
            <Typography.Text type='success' underline strong>
              Already have an account?
            </Typography.Text>
          </Link>
        ) : null}
      </Row>
      {breakpoint !== 'desktop' ? <Footer theme={SignUpFooter} /> : null}
    </ThemeProvider>
  )
}
