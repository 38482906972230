import { RegisterAccountForm } from 'containers/app/screens/SignUp/components/forms/actionForms/RegisterAccountForm'
import { ProfileForm } from 'containers/app/screens/SignUp/components/forms/actionForms/ProfileForm'
import { StoreForm } from 'containers/app/screens/SignUp/components/forms/actionForms/StoreForm'
import { AboutForm } from 'containers/app/screens/SignUp/components/forms/actionForms/AboutForm'

type FormSwitcherProps = {
  current: number
  nextStep: () => void
}
export const SignUpFormStepper = ({ current, nextStep }: FormSwitcherProps) => {
  switch (String(current)) {
  case '0':
    return <RegisterAccountForm nextStep={nextStep} />
    break
  case '1':
    return <ProfileForm nextStep={nextStep} />
    break
  case '2':
    return <StoreForm nextStep={nextStep} />
    break
  case '3':
    return <AboutForm />
    break
  default:
    return null
  }
}
