interface TextResponsiveSizes {
    xs: { desktop: string; mobile: string };
    s: { desktop: string; mobile: string };
    m: { desktop: string; mobile: string };
    l: { desktop: string; mobile: string };
    xl: { desktop: string; mobile: string };
}

export const textResponsiveSizes: TextResponsiveSizes = {
  xs: { desktop: "14", mobile: "14" },
  s: { desktop: "16", mobile: "16" },
  m: { desktop: "", mobile: "" },
  l: { desktop: "24", mobile: "24" },
  xl: { desktop: "", mobile: "" },
}
