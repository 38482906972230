import {useEffect, useState} from "react"
import {responsiveValue} from "containers/app/data/responsiveValue"

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  let breakpoint: "mobile" | "desktop" = 'mobile'

  if (screenSize >= responsiveValue) {
    breakpoint = 'desktop'
  }

  return { screenSize, breakpoint }

}
