import React, { ReactNode } from 'react'
import { responsiveSizes } from 'types/typography/header.types'
import { useScreenSize } from 'containers/app/hooks/useScreenSize'
import { textResponsiveSizes } from 'containers/app/data/text-responsive-sizes'
import { Typography } from 'antd'

interface HeaderProps {
  children: ReactNode
  size: responsiveSizes
  className?: string
  onClick?: () => void
}

export const Text: React.FC<HeaderProps> = ({ children, size, onClick, ...restProps }) => {
  const { breakpoint } = useScreenSize()
  const fontSize = textResponsiveSizes[size][breakpoint]
  return (
    <Typography.Text
      {...restProps}
      style={{ fontSize: `${fontSize}px`, lineHeight: `${parseInt(fontSize)}px` }}
      onClick={onClick}
    >
      {children}
    </Typography.Text>
  )
}
