import {ReactNode} from "react"
import "../../../../../../assets/styles/monserrat.scss"
import {Layout, Row} from "antd"
import {ThemeProvider} from "containers/app/components/providers/ThemeProvider"
import {signUpLayoutTheme} from "containers/app/screens/SignUp/data/theme/signUp/signUpLayoutTheme"
import styles from "./styles.module.scss"

const {Content } = Layout

type ISignInLayoutProps = {
    children: ReactNode
}

const SignUpLayout = ({children}: ISignInLayoutProps) => (
  <ThemeProvider theme={signUpLayoutTheme}>
    <Layout className={styles.layout}>
      <Content>
        <Row justify='center' style={{height: "100%"}}>
          {children}
        </Row>
      </Content>
    </Layout>
  </ThemeProvider>
)

export default SignUpLayout
