import {globalColors} from "../../../../data/colors"

export const signInFooter = {
  token: {
    inherit: true,
    fontSize: 14,
    colorBgContainer: globalColors.lightGrayBg,
    colorBorderSecondary: globalColors.lightGrayBg,
    paddingLG: 0
  }
}
