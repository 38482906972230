export const signUpSteppersItems = [
  {
    title: 'Register new account',
    content: 'Please provide your business email, phone number, monthly sales',
  },
  {
    title: 'Create',
    content: 'Please provide your First Name, Last Name, Password',
  },
  {
    title: 'Brand',
    content: 'Please provide your URL',
  },
  {
    title: 'Customize',
    content: 'Please provide your employees, main reason, role',
  },
]
