import { useState } from 'react'
import { signUpSteppersItems } from 'containers/app/screens/SignUp/data/theme/steppers/signUpSteppers'
import { Col, Row } from 'antd'
import { useScreenSize } from 'containers/app/hooks/useScreenSize'
import { StepperPreview } from './preview/StepperPreview'
import { StepperActions } from './actions/StepperActions'
import { ResponsiveStepperPreview } from './ResponsiveStepperPreview/ResponsiveStepperPreview'

export interface IStepItems {
  key: string
  title: string
  description: string
}

export const StepperSignUp = () => {
  const [current, setCurrent] = useState<number>(0)

  const nextStep = () => {
    setCurrent(current + 1)
  }

  const items: Array<IStepItems> = signUpSteppersItems.map(item => ({
    key: item.title,
    title: item.title,
    description: item.content,
  }))
  const { breakpoint } = useScreenSize()

  return (
    <Row style={{ width: '100%', minHeight: '100vh' }} justify='center'>
      <Col lg={12} xs={24}>
        <Row justify='end' align='middle' style={{ height: '100%' }}>
          {breakpoint !== 'mobile' ? (
            <StepperPreview current={current} items={items} />
          ) : (
            <ResponsiveStepperPreview current={current} items={items} />
          )}
        </Row>
      </Col>
      <Col lg={12} xs={24}>
        <StepperActions current={current} nextStep={nextStep} />
      </Col>
    </Row>
  )
}
