import {globalColors} from "../../../../../data/colors"

export const signUpStepperPreview = {
  token: {
    inherit: true,
    borderRadius: 16,
    colorBgBase: globalColors.white,
    colorSuccess: globalColors.purple,
    colorPrimary: globalColors.purple,
  }
}
