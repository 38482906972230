import { Card, Col, Progress, Row, Typography } from 'antd'
import Logo from 'assets/reactIcons/Logo'
import { singUpStepperResponsive } from 'containers/app/screens/SignUp/data/theme/steppers/singUpStepperResponsive'
import { globalColors } from 'containers/app/data/colors'
import { Header } from 'containers/shared/components/typography/Header'
import styles from './styles.module.scss'
import { ThemeProvider } from '../../../../../components/providers/ThemeProvider'

type IStepperPreviewProps = {
  current: number
  items: Array<{ key: string; title: string; description: string }>
}

export const ResponsiveStepperPreview = ({ current, items }: IStepperPreviewProps) => (
  <ThemeProvider theme={singUpStepperResponsive}>
    <Card className={styles.card}>
      <Row justify='center' className={styles.cardHeaderMb}>
        <Logo />
      </Row>
      <Row justify='center' className={styles.cardTitleMb}>
        <Header level={1} size='xl'>
          {'Welcome to Pitaya!'}
        </Header>
      </Row>
      <Row justify='center' className={styles.cardContentMb}>
        <Typography.Text type='secondary' className={styles.stepTitleMb}>
          {`Step ${current + 1} of ${items.length}`}
        </Typography.Text>
        <Progress
          strokeColor={{ '0%': globalColors.purple, '100%': globalColors.purple }}
          className={styles.stepTitleMb}
          percent={((current + 1) / items.length) * 100}
          showInfo={false}
        />
        <Row justify='center'>
          <Col span={24}>
            <Row justify='center'>
              <Header level={5} size='xs'>
                {items[current].title}
              </Header>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify='center'>
              <Typography.Text>{items[current].description}</Typography.Text>
            </Row>
          </Col>
        </Row>
      </Row>
    </Card>
  </ThemeProvider>
)
