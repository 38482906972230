import { Button, Col, Input, Row, Select, Space, TreeSelect, Typography } from 'antd'
/* eslint-disable-next-line */
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { StepperFormContext } from 'containers/app/screens/SignUp/providers/StepperFormProvider'
import { ArrowRightOutlined } from '@ant-design/icons'
import { monthlySalesOptions } from 'containers/app/screens/SignUp/data/theme/steppers/signUpStepperSelectOptions'
import axios from 'axios'
import ReactCountryFlag from 'react-country-flag'
import { Text } from 'containers/shared/components/typography/Text'
import { Header } from 'containers/shared/components/typography/Header'
import styles from './styles.module.scss'
const { Option } = Select

type RegisterAccountFormProps = {
  nextStep: () => void
}

export const RegisterAccountForm = ({ nextStep }: RegisterAccountFormProps) => {
  const { formData, setFormData } = useContext(StepperFormContext)
  const [country, setCountry] = useState([])
  useEffect(() => {
    axios.get('https://countriesnow.space/api/v0.1/countries').then(res => setCountry(res.data.data))
  }, [])
  return (
    <Row justify='center' style={{ width: '100%' }}>
      <Col flex='0 1 100%'>
        <Header level={3} size={'m'}>
          Register new account
        </Header>
      </Col>
      <Col flex='0 1 100%'>
        <Row>
          <Col flex='0 1 100%'>
            <Formik
              initialValues={{
                email: formData.email,
                phone: formData.sellerAttributes['phone'],
                monthlySales: formData.sellerAttributes['monthlySales'],
                countryCode:
                  country?.find(item => item.iso2 === formData.sellerAttributes['countryCode'])?.country || [],
              }}
              validate={values => {
                const errors = {}
                Object.keys(values).forEach(key => {
                  if (Array.isArray(values[key]) && !values[key][0]) {
                    errors[key] = 'Required field'
                  } else if (values[key] === '') {
                    errors[key] = 'Required field'
                  }
                })

                return errors
              }}
              onSubmit={values => {
                setFormData(prev => ({
                  ...prev,
                  email: values.email,
                  sellerAttributes: {
                    ...prev.sellerAttributes,
                    monthlySales: values.monthlySales,
                    countryCode: country?.find(item => item.country === values.countryCode)?.iso2,
                    phone: values.phone,
                  },
                }))
                nextStep()
              }}
            >
              {(props: FormikProps<any>) => (
                <Form>
                  <Space direction='vertical' size={32} style={{ width: '100%' }}>
                    <Field name='email'>
                      {({ field, meta }: FieldProps) => (
                        <>
                          <Input
                            {...field}
                            status={meta.touched && meta?.error && 'error'}
                            placeholder='Enter your business email'
                          />
                          <ErrorMessage name='email'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                    <Field name='phone'>
                      {({ field, meta }: FieldProps) => (
                        <>
                          <Input
                            {...field}
                            status={meta.touched && meta?.error && 'error'}
                            placeholder='Enter your phone number'
                          />
                          <ErrorMessage name='phone'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                    <Field name='monthlySales'>
                      {({ field, meta, form }: FieldProps) => (
                        <>
                          <Select
                            {...field}
                            style={{ width: '100%' }}
                            status={meta.touched && meta.error && 'error'}
                            placeholder='Your average monthly sales'
                            onBlur={() => form.setFieldTouched('monthlySales', true)}
                            onChange={value => {
                              field.onChange({
                                target: {
                                  name: field.name,
                                  value,
                                },
                              })
                            }}
                          >
                            {monthlySalesOptions.map(item => (
                              <Option key={item.value} value={item.value}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                          <ErrorMessage name='monthlySales'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                    <Field name='countryCode'>
                      {({ field, meta, form }: FieldProps) => (
                        <>
                          <TreeSelect
                            showSearch
                            treeIcon
                            style={{ width: '100%' }}
                            {...field}
                            onBlur={() => form.setFieldTouched('countryCode', true)}
                            onChange={value => {
                              field.onChange({
                                target: {
                                  name: field.name,
                                  value,
                                },
                              })
                            }}
                            status={meta.touched && meta.error && 'error'}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder='Country'
                            allowClear
                            treeDefaultExpandAll
                            treeData={country?.map(item => ({
                              title: item.country,
                              value: `${item.country}`,
                              icon: <ReactCountryFlag countryCode={item?.iso2} />,
                            }))}
                          />
                          <ErrorMessage name='countryCode'>
                            {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                          </ErrorMessage>
                        </>
                      )}
                    </Field>
                    <Button
                      block
                      size='large'
                      type='primary'
                      onClick={props.submitForm}
                      disabled={props.isSubmitting || !props.isValid}
                    >
                      <Row justify='center' align='middle'>
                        <Text className={styles.nextButtonContent} size='l'>
                          Next
                        </Text>
                        <ArrowRightOutlined style={{ fontSize: '26px' }} />
                      </Row>
                    </Button>
                  </Space>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
