import {globalColors} from "../../../../../data/colors"


export const signUpStepperAction = {
  token: {
    controlHeight: 60,
    colorPrimary: globalColors.purple,
    colorSuccess: globalColors.purple,
  },
  components: {
    Button: {
      algorithm: true,
      controlHeight: 50,
    },
  },
}
