import { Button, Col, Row, Select, Space, Typography } from 'antd'
/* eslint-disable-next-line */
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import React, { useContext } from 'react'
import { StepperFormContext } from 'containers/app/screens/SignUp/providers/StepperFormProvider'
import { ArrowRightOutlined } from '@ant-design/icons'
import { APP_USERS_CONGRADULATE } from 'constants/routes.constants'
import { useNavigate } from 'react-router-dom'
import {
  employeeCountOptions,
  reasonOptions,
  roleOptions,
} from 'containers/app/screens/SignUp/data/theme/steppers/signUpStepperSelectOptions'
import { inject, observer } from 'mobx-react'
import { Text } from 'containers/shared/components/typography/Text'
import { Header } from 'containers/shared/components/typography/Header'
import styles from './styles.module.scss'
const { Option } = Select

export const AboutForm = inject('sessionStore')(
  observer(({ sessionStore }: { sessionStore?: any }) => {
    const { formData, setFormData } = useContext(StepperFormContext)
    const navigate = useNavigate()
    return (
      <Row justify='center' style={{ width: '100%' }}>
        <Col flex='0 1 100%'>
          <Header level={3} size={'m'}>
            Tell us more about you and your company to ensure you have the best tools for you
          </Header>
        </Col>
        <Col flex='0 1 100%'>
          <Row>
            <Col flex='0 1 100%'>
              <Formik
                initialValues={{
                  employeesCount: formData.sellerAttributes['employeesCount'],
                  usageReason: formData.sellerAttributes['usageReason'],
                  companyRole: formData.sellerAttributes['companyRole'],
                }}
                validate={values => {
                  const errors = {}
                  Object.keys(values).forEach(key => {
                    if (Array.isArray(values[key]) && !values[key][0]) {
                      errors[key] = 'Required field'
                    }
                  })

                  return errors
                }}
                onSubmit={async values => {
                  setFormData(prev => ({
                    ...prev,
                    sellerAttributes: {
                      ...prev.sellerAttributes,
                      employeesCount: values.employeesCount,
                      usageReason: values.usageReason,
                      companyRole: values.companyRole,
                    },
                  }))
                  await sessionStore.signUp({
                    ...formData,
                    sellerAttributes: {
                      ...formData.sellerAttributes,
                      employeesCount: values.employeesCount,
                      usageReason: values.usageReason,
                      companyRole: values.companyRole,
                    },
                  })
                  navigate(APP_USERS_CONGRADULATE)
                }}
              >
                {(props: FormikProps<any>) => (
                  <Form>
                    <Space direction='vertical' size={32} style={{ width: '100%' }}>
                      <Field name='employeesCount'>
                        {({ field, meta, form }: FieldProps) => (
                          <>
                            <Select
                              {...field}
                              status={meta?.touched && meta?.error && 'error'}
                              style={{ width: '100%' }}
                              placeholder='How many employees do you have?'
                              onBlur={() => form.setFieldTouched('employeesCount', true)}
                              onChange={value => {
                                field.onChange({
                                  target: {
                                    name: field.name,
                                    value,
                                  },
                                })
                              }}
                            >
                              {employeeCountOptions.map(item => (
                                <Option value={item.value} key={item.value}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                            <ErrorMessage name='employeesCount'>
                              {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                            </ErrorMessage>
                          </>
                        )}
                      </Field>
                      <Field name='usageReason'>
                        {({ field, meta, form }: FieldProps) => (
                          <>
                            <Select
                              {...field}
                              status={meta?.touched && meta?.error && 'error'}
                              onBlur={() => form.setFieldTouched('usageReason', true)}
                              style={{ width: '100%' }}
                              placeholder='What is the main reason you are using Piitaya?'
                              onChange={value => {
                                field.onChange({
                                  target: {
                                    name: field.name,
                                    value,
                                  },
                                })
                              }}
                            >
                              {reasonOptions.map(item => (
                                <Option key={item.value} value={item.value}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                            <ErrorMessage name='usageReason'>
                              {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                            </ErrorMessage>
                          </>
                        )}
                      </Field>
                      <Field name='companyRole'>
                        {({ field, meta, form }: FieldProps) => (
                          <>
                            <Select
                              {...field}
                              status={meta?.touched && meta?.error && 'error'}
                              style={{ width: '100%' }}
                              placeholder='Which best describe your role?'
                              onBlur={() => form.setFieldTouched('companyRole', true)}
                              onChange={value => {
                                field.onChange({
                                  target: {
                                    name: field.name,
                                    value,
                                  },
                                })
                              }}
                            >
                              {roleOptions.map(item => (
                                <Option key={item.value} value={item.value}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                            <ErrorMessage name='companyRole'>
                              {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                            </ErrorMessage>
                          </>
                        )}
                      </Field>
                      <Button
                        block
                        size='large'
                        type='primary'
                        onClick={props.submitForm}
                        disabled={props.isSubmitting || !props.isValid}
                      >
                        <Row justify='center' align='middle'>
                          <Text className={styles.nextButtonContent} size='l'>
                            Next
                          </Text>
                          <ArrowRightOutlined style={{ fontSize: '26px' }} />
                        </Row>
                      </Button>
                    </Space>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }),
)
