import { Button, Col, Input, Row, Space, Typography } from 'antd'
/* eslint-disable-next-line */
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import React, { useContext } from 'react'
import { StepperFormContext } from 'containers/app/screens/SignUp/providers/StepperFormProvider'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Text } from 'containers/shared/components/typography/Text'
import { Header } from 'containers/shared/components/typography/Header'
import styles from './styles.module.scss'

type RegisterAccountFormProps = {
  nextStep: () => void
}

export const StoreForm = ({ nextStep }: RegisterAccountFormProps) => {
  const { formData, setFormData } = useContext(StepperFormContext)
  return (
    <Row justify='center' style={{ width: '100%' }}>
      <Col flex='0 1 100%'>
        <Header level={3} size={'m'}>
          Set your store URL
        </Header>
      </Col>
      <Col flex='0 1 100%'>
        <Row>
          <Col flex='0 1 100%'>
            <Formik
              initialValues={{
                username: formData.sellerAttributes['username'],
              }}
              validate={values => {
                const errors = {}
                Object.keys(values).forEach(key => {
                  if (values[key] === '') {
                    errors[key] = 'Required field'
                  }
                })

                return errors
              }}
              onSubmit={values => {
                setFormData(prev => ({
                  ...prev,
                  sellerAttributes: {
                    ...prev.sellerAttributes,
                    username: values.username,
                  },
                }))
                nextStep()
              }}
            >
              {(props: FormikProps<any>) => (
                <Form>
                  <Space direction='vertical' size={32} style={{ width: '100%' }}>
                    <Row align='middle' wrap={false} style={{ width: '100%', gap: '8px' }}>
                      <Col flex='0 1 70%'>
                        <Field name='username'>
                          {({ field, meta }: FieldProps) => (
                            <>
                              <Input
                                {...field}
                                status={meta.touched && meta?.error && 'error'}
                                placeholder='Your url'
                              />
                              <ErrorMessage name='username'>
                                {(msg: string) => <Typography.Text type='danger'>{msg}</Typography.Text>}
                              </ErrorMessage>
                            </>
                          )}
                        </Field>
                      </Col>
                      <Col>
                        <Typography.Text strong>.pitayaapp.io</Typography.Text>
                      </Col>
                    </Row>
                    <Button
                      block
                      size='large'
                      type='primary'
                      onClick={props.submitForm}
                      disabled={props.isSubmitting || !props.isValid}
                    >
                      <Row justify='center' align='middle'>
                        <Text className={styles.nextButtonContent} size='l'>
                          Next
                        </Text>
                        <ArrowRightOutlined style={{ fontSize: '26px' }} />
                      </Row>
                    </Button>
                  </Space>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
