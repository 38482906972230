import {Link} from "react-router-dom"
import {Typography, Row} from "antd"
import {signInFooter} from "containers/app/screens/SignIn/data/theme/footerSignInTheme"
import {ThemeProvider} from "../../../../../components/providers/ThemeProvider"

export const SignUpFooter = () => (
  <ThemeProvider theme={signInFooter}>
    <Row justify='space-between'>
      <Typography.Text type='secondary'>
        Copyright ©Pitaya. All rights reserved.
      </Typography.Text>
      <Link to='#'>
        <Typography.Text underline>
          Term and Conditions
        </Typography.Text>
      </Link>
      <Link to='#'>
        <Typography.Text underline>
          Privacy Policy
        </Typography.Text>
      </Link>
    </Row>
  </ThemeProvider>
)
