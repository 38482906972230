import { Row, Typography } from 'antd'
import Logo from 'assets/reactIcons/Logo'
import { Link } from 'react-router-dom'
import { APP_USERS_SIGN_IN_PATH } from 'constants/routes.constants'

export const PreviewTitle = () => (
  <Row justify='space-between' align='middle' style={{ gap: '10px', width: '100%' }}>
    <Logo />
    <Link to={APP_USERS_SIGN_IN_PATH}>
      <Typography.Text type='success' underline strong>
        Already have an account?
      </Typography.Text>
    </Link>
  </Row>
)
