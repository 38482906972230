import SignUpLayout from 'containers/app/screens/SignUp/layout/SignUpLayout'
import { SignUpComponent } from 'containers/app/screens/SignUp/components/page/SignUp/SignUpComponent'
import StepperFormProvider from 'containers/app/screens/SignUp/providers/StepperFormProvider'

const SignUp = () => (
  <StepperFormProvider>
    <SignUpLayout>
      <SignUpComponent />
    </SignUpLayout>
  </StepperFormProvider>
)

export default SignUp
