import React, { ReactNode } from 'react'
import { responsiveSizes } from 'types/typography/header.types'
import { useScreenSize } from 'containers/app/hooks/useScreenSize'
import { headerResponsiveSizes } from 'containers/app/data/header-responsive-sizes'
import { Typography } from 'antd'

interface HeaderProps {
  level: 2 | 5 | 1 | 3 | 4 | undefined
  children: ReactNode | string
  size: responsiveSizes
}

export const Header: React.FC<HeaderProps> = ({ level, children, size, ...restProps }) => {
  const { breakpoint } = useScreenSize()
  const fontSize = headerResponsiveSizes[size][breakpoint]
  return (
    <Typography.Title
      level={level}
      {...restProps}
      style={{ fontSize: `${fontSize}px`, lineHeight: `${parseInt(fontSize) + 8}px`, margin: 0, textAlign: 'center' }}
    >
      {children}
    </Typography.Title>
  )
}
